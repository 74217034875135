import { Component, Prop, Watch } from 'vue-property-decorator'
import { ArcClaimDto, ArcDto, ArcReviewSiteDto, BookDto } from 'booksprout'
import { ArcServiceInterface, CommonBaseMixin, inject } from '../../index'
import AddArcLinks from '../../../../../apps/app/src/modules/arcs/pages/modals/AddLinks.vue'
import AddArcLinksDialog from '../../../../../apps/app/src/modules/arcs/pages/components/AddArcLinksDialog.vue'

interface SiteWithReview {
  site: number,
  reviewSite: ArcReviewSiteDto
}

@Component({
  data () {
    return {
      arcSitesList: []
    }
  },
  components: {
    AddArcLinks,
    AddArcLinksDialog
  }
})
export default class ArcSites extends CommonBaseMixin {
  @Prop({ type: Array }) readonly arcSites!: ArcReviewSiteDto[]
  @Prop({ type: Object }) readonly arc!: ArcDto
  @Prop({ type: Object }) book!: BookDto
  @Prop({ type: Object }) readonly claim!: ArcClaimDto
  @Prop({ type: Boolean }) readonly showAll!: boolean

  @inject('ArcService')
  arcService!: ArcServiceInterface<ArcDto>

  @Watch('arcSites')
  onArcSitesChanged (to: any) {
    this.generateArcSitesList()
  }

  @Watch('claim')
  onClaimChanged (to: any) {
    this.generateArcSitesList()
  }

  selectedSiteReview: ArcReviewSiteDto = {} as unknown as ArcReviewSiteDto

  // add site links modal
  arcSitesList: ArcReviewSiteDto[] | SiteWithReview[] = []

  /**
   * Check if Arc belongs to the authenticated user
   */
  get userOwnsArc () {
    return this.arc.book?.userId === this.mimicOrAuthenticatedUser.id
  }

  /**
   * If the claim has been passed in to this component, make sure we return the correct reviewSite to the BsSiteBtn
   * so it can work out whether it needs to be disabled or not.
   * @param site
   */
  getReviewSite (site: ArcReviewSiteDto | SiteWithReview) {
    if (this.claim && (site as SiteWithReview).reviewSite) {
      return (site as SiteWithReview).reviewSite
    }

    return site
  }

  /**
   * Only ArcReviewSiteDto has a "state" prop
   * this method avoids duplicate key error while using "site.site + site.state"
   * as a key directly in vue file
   * @param site
   */
  getKey (site: ArcReviewSiteDto | SiteWithReview) {
    if ((site as ArcReviewSiteDto).state) {
      return this.arc.id + '_' + site.site + (site as ArcReviewSiteDto).state
    }

    if ((site as SiteWithReview).reviewSite) {
      return this.arc.id + '_' + (site as SiteWithReview).site + (site as SiteWithReview).reviewSite.id
    }

    const uniqueKey = Math.random().toString(16).substr(2, 8)
    return this.arc.id + '_' + site.site + uniqueKey
  }

  getSiteLink (site: ArcReviewSiteDto): undefined | string {
    if (!site) {
      return void 0
    }

    let url = site.link
    if (!url) {
      url = this.arcService.getReviewLinkForSite(site.site, this.arc)
    }

    // if link doesn't have protocol
    // it will be treated as internal link
    if (!url?.startsWith('http')) {
      url = `https://${url}`
    }

    return url
  }

  async markReviewSiteAsSeen(site: ArcReviewSiteDto) {
    // for arc list we are passing through MODULE_CONSTANTS.ARCS.REVIEWS.FLAGS
    // which won't contain an "id" prop and we don't try
    // to mark review as read as the link supplied will be from author
    if (!site.id) {
      return
    }

    const updatedSite = await this.arcService.markReviewSiteAsSeen(site.id)

    if (this.claim && updatedSite) {
      const existingSite = (this.arcSitesList as SiteWithReview[]).find((f: SiteWithReview) => f.site === updatedSite.site)
      if (existingSite) {
        existingSite.reviewSite = updatedSite
      }
    } else if (updatedSite) {
      const existingSiteIndex = (this.arcSitesList as ArcReviewSiteDto[]).findIndex(f => f.site === updatedSite.site)
      ;(this.arcSitesList as ArcReviewSiteDto[]).splice(existingSiteIndex, 1, updatedSite)
      this.$set(this, 'arcSitesList', this.arcSitesList)
    }
  }

  /**
   * If a claim is passed in, we want to list ALL of the sites that claim wants and then just build
   * a list based on the reviewSites the user has populated.
   * This means all the sites the user hasn't done yet but are required will show as greyed out
   */
  generateArcSitesList () {
    if (!this.showAll) {
      return this.arcSitesList = this.arcSites
    }

    const arcSitesList = [] as SiteWithReview[]
    for (const arcSite of this.arcService.getArcSitesFromFlags(this.claim?.reviewFlags | (this.claim?.optionalReviewFlags || 0), this.AUDIBLE)) {
      arcSitesList.push({
        site: arcSite.site,
        reviewSite: this.arcSites.find(f => f.site === arcSite.site) as ArcReviewSiteDto
      })
    }
    return this.$set(this, 'arcSitesList', arcSitesList)
  }

  showAddArcLinks (site: ArcReviewSiteDto) {
    this.$q.dialog({
      component: AddArcLinksDialog,
      parent: this,
      arc: this.arc,
      selectedSite: site?.site
    }).onOk(() => {
      this.$emit('linkUpdated')
    })
  }

  mounted () {
    this.generateArcSitesList()
  }
}
